<template>
  <div class="layout-wrapper">
    <the-progress-bar />
    <div class="layout-main" />
    <div class="title">
      <div class="p-grid p-flex-column">
        <div class="p-col p-as-center p-mb-2">
          <img
            src="/assets/images/logo-login.png"
            alt="Herta Security logo"
            width="300"
          />
        </div>
        <div class="p-col p-as-center p-mb-4">
          <h2>{{ title }}</h2>
        </div>
      </div>
    </div>
    <div class="centered">
      <div class="form-data">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import TheProgressBar from "./TheProgressBar";

export default {
  name: "TheLayout",
  components: {
    TheProgressBar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.title {
  position: fixed;
  top: 30%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.centered {
  position: fixed;
  top: 53%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.form-data {
  width: 420px;
}
.layout-wrapper {
  background: linear-gradient(180deg, #4d505b 0, #3b3e47);
  color: white;
}
</style>
